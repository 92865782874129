<template>
  <div>
    {{ $t('signup.terms-texts.0') }}
    <IubendaDocumentLink
      type="terms"
      @click.stop
    />
    {{ $t('signup.terms-texts.1') }}
    <IubendaDocumentLink
      type="privacyPolicy"
      @click.stop
    /><br>
    {{ $t('signup.terms-texts.2') }}
    <IubendaConsentLink />
    {{ $t('signup.terms-texts.3') }}
    <a
      :href="imprintUrl"
      target="_blank"
      rel="noopener noreferrer"
    >{{ $t('navigation.labels.imprint') }}</a>
  </div>
</template>
<script>
import brandingMixin from '@/mixins/branding'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'

export default {
  components: {
    IubendaConsentLink,
    IubendaDocumentLink
  },
  mixins: [brandingMixin]
}
</script>

<template>
  <div class="mt-6 d-flex align-center flex-column">
    <div>
      <h1 class="mb-4 display-1 black--text font-weight-black text-center">
        <span v-if="productType === productTypeEnum.BEGINNER">
          {{ $t('payment.title-beginner-package') }}
        </span>
        <span v-else>{{ $t('payment.title') }}</span>
      </h1>
    </div>
    <v-row class="text-center">
      <v-col>
        <div class="d-flex text-caption mt-2 mb-md-6 flex-row flex-wrap justify-center">
          <p class="px-0 mx-12 mx-md-8 mb-2 nowrap">
            <v-icon
              class="ml-0 px-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            {{ $t('payment.benefits.option-one') }}
          </p>
          <p class="px-0 mr-8 mx-md-8 mb-2 nowrap">
            <v-icon
              class="ml-0 px-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            <span v-if="isYearly"> {{ $t('payment.benefits.option-two') }}</span>
            <span v-else> {{ $t('payment.benefits.option-three') }}</span>
          </p>
          <p class="px-0 mb-2 mx-md-8 nowrap">
            <v-icon
              class="ml-0 px-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            {{ $t('payment.benefits.option-four') }}
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="5"
        offset-lg="1"
        order="2"
        order-lg="1"
      >
        <p class="font-weight-bold pa-4">
          {{ $t('payment.billing-data.title') }}
        </p>
        <Subscribe
          ref="subscribe"
          :country-code="countryCode"
          :product-type="productType"
          :readable-ids="readableId"
          :email="$auth.user.email"
          is-form-only
          hide-submit
          show-country
          @completed="completed"
          @previewOrder="previewOrder = $event"
          @state="state = $event"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="5"
        order="1"
        order-lg="2"
      >
        <v-card class="pa-8 pt-4 mx-4">
          <div>
            <p class="font-weight-bold px-0">
              {{ $t('payment.billing-info.title') }}
            </p>
            <v-radio-group
              v-model="isYearly"
              row
            >
              <RadioButton
                class="mr-3 flex-grow-1"
                :value="true"
                :group-value="isYearly"
              >
                <div class="font-weight-bold pb-2">
                  {{ $t('payment.billing-info.charge.label-two') }}
                </div>
                <div>{{ $t('payment.billing-info.description', {percent: 20}) }}</div>
              </RadioButton>
              <RadioButton
                class="flex-grow-1"
                :value="false"
                :group-value="isYearly"
              >
                <div class="font-weight-bold pb-2 pb-6">
                  {{ $t('payment.billing-info.charge.label-one') }}
                </div>
              </RadioButton>
            </v-radio-group>
          </div>
          <PreviewOrder
            v-if="previewOrder"
            class="col-12 mt-0 mb-5 pa-0"
            :preview-order="previewOrder"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-0 ma-md-4 d-flex">
      <v-btn
        outlined
        :color="$vuetify.theme.themes.light.markero.blue"
        class="mr-6 mx-md-8 px-12 px-md-16 py-6"
        :disabled="state === 'customerForm.submitting'"
        @click="goBack"
      >
        {{ $t('labels.back') }}
      </v-btn>
      <v-btn
        :color="$vuetify.theme.themes.light.markero.blue"
        class="ml-6 mx-md-8 px-12 px-md-16 py-6 white--text"
        :loading="state === 'customerForm.submitting'"
        @click="submit"
      >
        Weiter
      </v-btn>
    </v-row>

    <v-row class="ma-5">
      <v-col
        md="8"
        offset-md="2"
      >
        <v-card
          elevation="0"
          class="blue lighten-4 pa-4 d-flex text-center"
        >
          <span>
            {{ $t('payment.disclaimer-one') }}
            <v-img
              class="mx-1 inline-image"
              :src="require('@/../public/img/logo-bottimmo.png')"
              width="100"
              height="20"
              contain
            />.
            {{ $t('payment.disclaimer-two') }} Billwerk+.
            {{ $t('payment.disclaimer-three') }}
          </span>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Subscribe from '@/modules/billwerk/subscribe/index.vue'
import RadioButton from '@/components/RadioButton.vue'
import featureMixin from '@/mixins/feature'
import { ProductType } from '@/modules/productPackages/enums/ProductType'
import PreviewOrder from '../billwerk/subscribe/PreviewOrder.vue'

export default {
  components: { Subscribe, RadioButton, PreviewOrder },
  mixins: [featureMixin],
  data () {
    return {
      previewOrder: null,
      productTypeEnum: ProductType,
      readableId: Array.isArray(this.$route.query?.readableId) ? this.$route.query?.readableId : [this.$route.query?.readableId],
      productType: String(this.$route.query?.productType || ''),
      isYearly: !!this.$route.query?.isYearly,
      monthlyPrice: this.$route.query?.monthlyPrice,
      yearlyPrice: this.$route.query?.yearlyPrice,
      countryCode: String(this.$route.query?.countryCode || ''),
      currencySymbol: this.$route.query?.currencySymbol,
      hasPaymentProvider: false,
      dialog: true,
      state: null,
      isBillingInProgress: false
    }
  },
  computed: {
    isAbortPaymentCallback () {
      return window.location.search.includes('trigger=Abort')
    }
  },
  watch: {
    isYearly (newValue) {
      const timePeriod = newValue ? 'yearly' : 'monthly'
      this.updateReadableIds(timePeriod)
      this.setToSessionStorage()
    }
  },
  methods: {
    submit () {
      this.$refs.subscribe.submitCustomerForm()
    },
    completed () {
      this.isBillingInProgress = false
      sessionStorage.removeItem('markeroRegistrationFlow')
      this.$router.push('/product-finder')
    },
    updateReadableIds (timePeriod) {
      this.readableId = this.readableId.map(id => this.replaceLastSegment(id, timePeriod))
    },
    replaceLastSegment (id, newSegment) {
      const segments = id.split('-')
      if (segments[segments.length - 1] === 'monthly' || segments[segments.length - 1] === 'yearly') {
        segments[segments.length - 1] = newSegment
      }
      return segments.join('-')
    },
    setToSessionStorage () {
      sessionStorage.setItem('productPackageQuery', JSON.stringify(
        {
          productType: this.productType,
          isYearly: this.isYearly,
          countryCode: this.countryCode,
          currencySymbol: this.currencySymbol,
          companyId: this.companyId,
          monthlyPrice: this.monthlyPrice,
          yearlyPrice: this.yearlyPrice,
          readableId: this.readableId
        }
      ))
    },
    goBack () {
      this.$router.push({
        name: 'product-packages',
        query: {
          countryCode: this.$route.query?.countryCode,
          readableId: this.$route.query?.readableId,
          currencySymbol: this.$route.query?.currencySymbol,
          isYearly: this.$route.query?.isYearly,
          companyId: this.$route.query?.companyId
        }
      })
    }
  }
}
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
.inline-image {
    display: inline-block;
    vertical-align: middle;
}

::v-deep fieldset   {
  background-color: white;
}
</style>
